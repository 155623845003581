///
/// Default
///

    @media #{$mq-default} {

        .book-cover {
          width: 100%;
        }

        .place-order {

          &__title {color: $blackgrey;}

            &__verse {
              color: $red;
              font-family: $inknut;
              font-weight: 500;
            }

            &__sermon-blurb, &__book-info {
              font-family: $freight;
              font-weight: 600;
              font-style: italic;
              color: $blackgrey;
            }

            &__sermon-title, &__buy {
              color: $red;
              font-family: $inknut;
              font-weight: 600;
            }

            &__buy button {
              padding: 0;
              margin: 0;
              background: transparent;
              border: none;
            }

            &__buy:hover, &__sermon-title:hover {
              color: $red;
              text-decoration: none;
            }

            &__underline {
              border: 2px solid $red;
            }
        }



    } // end $mq-default


///
/// Extra Small
///

    @media #{$mq-extra-small} {
      .book-cover {
        margin: 20px 0 28px 0;
      }

      .place-order {

        &__title {
          font-size: 40px;
          line-height: 58px;
          margin-top: 15px;
        }

        &__verse {
          font-size: 24px;
          line-height: 38px;
          margin-top: 0;
        }

        &__sermon-blurb, &__book-info {
          font-size: 18px;
          line-height: 30px;
          padding-top: 8px;
        }

        &__book-info {
          padding-top: 32px;
        }

        &__sermon-title, &__buy {
          font-size: 21px;
        }

        &__sermon-title{margin: 14px 0 15px 0;}

        &__buy {margin: 19px 0 18px 0; line-height: 26px;}

        &__underline {
          width: 280px;
        }

        &__order-underline {
          width: 247px;
          margin-bottom: 20px;
        }

        &__rudders-underline {margin-bottom: 75px;}
      }

    } // end $mq-extra-small


///
/// Small
///

    @media #{$mq-small} {

      .book-cover {
        margin: 20px 0 28px 0;
      }
      .place-order {

        &__title {
          font-size: 44px;
          line-height: 60px;
          margin-top: 15px;
        }

        &__verse {
          font-size: 24px;
          line-height: 40px;
          margin-top: 15px;
        }

        &__sermon-blurb, &__book-info {
          font-size: 18px;
          line-height: 28px;
          padding-top: 18px;
        }

        &__book-info {
          padding-top: 32px;
        }

        &__sermon-title, &__buy {
          font-size: 24px;
        }

        &__sermon-title{margin: 14px 0 15px 0;}

        &__buy {margin: 19px 0 18px 0; line-height: 26px;}

        &__underline {
          width: 308px;
        }

        &__order-underline {
          width: 275px;
          margin-bottom: 20px;
        }

        &__rudders-underline {margin-bottom: 75px;}
      }

    } // end $$mq-small


///
/// Small Plus
///

    @media #{$mq-small-plus} {

      .place-order{

        &__verse{line-height: 40px;}

        &__sermon-blurb, &__book-info {font-size: 26px; line-height: 40px;}
      }
    } //end $mq-small-plus


///
/// Medium
///

    @media #{$mq-medium} {

        .book-cover {
          padding-top: 20px;
        }

        .place-order {

          &__title {font-size: 54px; margin-top: 25px;}

          &__verse {
            margin-top: 15px;
            font-size: 30px;
            line-height: 52px;
          }

          &__sermon-blurb, &__book-info {
            font-size: 24px;
            width: 60%;
            line-height: 39px;
            letter-spacing: .5px;
            margin: 10px 0 25px 0;
          }

          &__sermon-title, &__buy {
            font-size: 28px;
          }

          &__buy{line-height: 43px; margin: 19px 0 13px 0;}

          &__underline {
            width: 352px;
            border: 2px solid $red;
            margin-bottom: 30px;
          }

          &__order-underline {width: 311px;}

          &__rudders-underline {margin-bottom: 135px;}
        }

    } // end $mq-medium


///
/// Large
///

    @media #{$mq-large} {

      .place-order {

        &__title {font-size: 68px; font-weight: 400; margin-top: 30px;}

        &__verse {
          font-size: 30px;
          margin-top: 27px;
        }

        &__place-order, &__sermon-blurb, &__book-info {
          width: 87%;
        }

        &__sermon {
          margin-right: 1%;
        }

        &__sermon, &__buy-book {
          display: inline-block;
          width: 49%;
        }

        &__sermon-blurb, &__book-info {
          font-size: 21px;
          margin: 0 0 0 0;
        }

        &__sermon-blurb {padding-top: 0;}

        &__sermon-title {margin: 16px 0 10px 0;}

        &__buy{line-height: 53px; margin-bottom: 7px;}

        &__sermon-title, &__buy {
          font-size: 25px;
          display: inline-block;
        }

        &__underline {
          width: 317px;
          border: 2px solid $red;
          margin-bottom: 150px;
        }

        &__order-underline {
          width: 276px;
        }

      }


    } // end $mq-large


///
/// ipad landscape
///

    @media #{$mq-medium-landscape} {

      .place-order {

        &__title {
          margin-top: 47px;
          font-size: 72px;
        }

        &__verse {
          width: 872px;
          font-size: 32px;
          margin: 51px 0 20px 0;
          line-height: 62px;
        }

        &__sermon-blurb, &__book-info {
          font-size: 24px;
          width: 381px;
          line-height: 43px;
        }


        &__book-info {width: 440px; padding-top: 0px; line-height: 43px;}

        &__buy-book {margin-top: 0;}

        &__buy {line-height: 56px;}

        &__sermon-title, &__buy{
          font-size: 28px;
          margin: 14px 0 9px 0;
        }

        &__underline {
          width: 353px;
          margin-bottom: 105px;
        }

        &__order-underline {
          width: 309px;
        }
      }

    }// end $mq-medium-landscape

///
/// Largest
///

    @media #{$mq-largest} {

      .place-order {

        &__title {font-size: 69px;}

        &__verse {
          font-size: 31px;
          width: 821px;
          line-height: 63px;
          margin-bottom: 0px;
          padding-bottom: 30px;
        }

        &__buy{line-height: 53px;}

        &__sermon{width: 39%;}

        &__sermon-blurb, &__book-info {margin: 0 0 4px 0; line-height: 45px;}

        &__sermon-title, &__buy {margin-bottom: 10px; font-size: 28px;}

        &__place-order, &__sermon-title {font-size: 28px;}

        &__underline {width: 354px; margin-bottom: 200px;}

        &__order-underline {width: 308px;}
      }


    } // end $mq-largest
