///
/// Default
///

    @media #{$mq-default} {

        .introduction {

            &__image {
              width: 100%;
            }

            &__headline {
              font-family: $freight;
              font-weight: 600;
              font-style: italic;
              color: $blackgrey;
            }

            &__title {color: $blackgrey;}

            &__place-order {
              color: $red;
              font-family: $inknut;
              font-weight: 500;
            }

            &__place-order:hover {
              text-decoration: none;
              color: $red;
            }

            &__underline {
              border: 2px solid $red;
            }

            &__body, &__author {
              font-family: $aktiv;
              font-weight: 500;
              color: $blackgrey;
            }

            &__line-break {
              border: 1px solid $blackgrey;
              width: 5%;
              margin-top: 10px;
            }

            &__closing {
              font-family: $inknut;
              font-weight: 500;
              color: $red;
              padding-bottom: 5px;
            }

        }



    } // end $mq-default

  ///
  /// Extra Small
  ///

      @media #{$mq-extra-small} {

        .introduction {

          &__image {width: 100%; margin-top: 25px;}

          &__medium, &__large, &__medium-landscape, &__largest {
            display: none;
          }

          &__headline {
            padding-top: 18px;
            font-size: 17px;
            line-height: 28px;
            letter-spacing: .1px;
          }

          &__place-order {
            font-size: 24px;
            padding-top: 6px;
          }

          &__underline {
            width: 230px;
            margin-top: 17px;
          }

          &__title {
            font-size: 40px;
            line-height: 58px;
            padding-top: 20px;
            margin-left: -2px;
          }

          &__body, &__author{
            font-size: 14px;
            line-height: 24px;
            letter-spacing: .1px;
            text-align: justify;
          }

          p {text-indent: 13px; margin-bottom: 0px;}

          &__closing {
            font-size: 24px;
            line-height: 38px;
          }

        }

      } // end $$mq-extra-small



///
/// Small
///

    @media #{$mq-small} {

      .introduction {

        &__image {
          width: 100%;
          margin-top: 25px;
        }

        &__medium, &__large, &__medium-landscape, &__largest {
          display: none;
        }

        &__headline {
          padding-top: 18px;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -.25px;
        }

        &__place-order {
          font-size: 24px;
          padding-top: 6px;
        }

        &__underline {
          width: 230px;
          margin-top: 17px;
        }

        &__title {
          font-size: 44px;
          line-height: 60px;
          padding-top: 20px;
        }

        &__body, &__author{
          font-size: 14px;
          line-height: 24px;
          letter-spacing: .1px;
          text-align: justify;
        }

        p {text-indent: 13px; margin-bottom: 0px;}

        &__closing {
          font-size: 24px;
          line-height: 40px;
        }

      }

    } // end $mq-small

///
/// Small Plus
///

    @media #{$mq-small-plus} {

      .introduction{

        &__headline {font-size: 22px; line-height: 36px;}

        &__title {font-size: 44px;}
      }
    } //end $mq-small-plus

///
/// Medium
///

    @media #{$mq-medium} {

        .introduction {

            &__image {
              position: absolute;
              margin: 106px 0 0 351px;
              width: 321px;
            }

            &__medium {
              display: inline;
            }

            &__small, &__large, &__medium-landscape, &__largest {
              display: none;
            }

            &__title {
              display: block;
              font-size: 54px;
              letter-spacing: -3px;
              margin-bottom: 16px;
            }

            &__headline {
              font-size: 24px;
              letter-spacing: .5px;
              line-height: 39px;
              position: absolute;
              width: 321px;
              margin: 605px 0 0 351px;
            }

            &__place-order {
              position: absolute;
              width: 275px;
              margin: 400px 0 0 175px;
              font-size: 28px;
            }

            &__underline {
              position: absolute;
              width: 267px;
              margin: 855px 0 0 351px;
            }

            &__body{
              width: 321px;
              font-size: 12px;
              line-height: 24px;
              text-align: justify;
            }

            p {text-indent: 21px; margin-bottom: 0px;}

            &__author {
              font-size: 11px;
              line-height: 22px;
            }

            &__line-break {
              width: 2%;
            }

            &__closing {
              font-size: 30px;
              line-height: 52px;
              padding: 13px 0 24px 0;
            }
        }

    } // end $mq-medium


///
/// Large
///

    @media #{$mq-large} {

        .introduction {

          &__image {
            position: absolute;
            width: 401px;
            margin: 130px 0 0 431px;
          }

          &__large {
            display: inline;
          }

          &__small, &__medium, &__medium-landscape, &__largest {
            display: none;
          }

          &__start {
            margin: 75px 0 0 0;
          }

          &__title {
            font-size: 68px;
            margin-top: 15px;
            font-weight: 400;
          }

          &__content {margin-top: 35px;}

          &__body, &__author {
            font-size: 12px;
            line-height: 24px;
            width: 401px;
          }

          p {text-indent: 21px; margin-bottom: 0px;}

          &__line-break {
            width: 2%;
          }

          &__headline {
            width: 401px;
            margin: 731px 0 0 431px;
            font-weight: 600;
            font-size: 21px;
            line-height: 38px;
          }

          &__place-order {
            margin: 435px 0 0 215px;
            font-size: 25px;
            letter-spacing: .5px;
          }

          &__underline {
            width: 244px;
            margin: 922px 0 0 432px;
          }

          &__closing {
            font-size: 27px;
            line-height: 49px;
            padding: 29px 0 0 0;
          }
        }


    } // end $mq-large

    ///
    /// ipad landscape
    ///

      @media #{$mq-medium-landscape} {

        .introduction {

          &__medium-landscape {
            display: inline;
          }

          &__image {
            position: absolute;
            margin: 124px 0 0 476px;
            width: 436px;
          }

          &__small, &__large, &__medium, &__largest {
            display: none;
          }

          &__start {
            margin: 93px 0 0 5px;
          }

          &__title {
            font-size: 72px;
            margin: 39px 0 22px 0;
          }

          &__body, &__author {
            width: 436px;
            font-size: 14px;
            line-height: 26px;
          }

          p {text-indent: 21px; margin-bottom: 0px;}


          &__headline {
            margin: 775px 0 0 476px;
            font-size: 24px;
            line-height: 43px;
            width: 436px;
          }

          &__place-order {
            font-size: 28px;
            letter-spacing: 1px;
            margin: 474px 0 0 238px;
            width: 345px;
          }

          &__underline {
            width: 283px;
            margin: 1008px 0 0 476px;
          }

          &__closing {
            font-size: 32px;
            line-height: 62px;
            width: 915px;
          }

        }

      } //end $mq-medium-landscape

///
/// Largest
///

    @media #{$mq-largest} {

      .introduction {

        &__start {
          margin-top: 112px;
        }

        &__title {
          margin:38px 0 41px 0;
          font-size: 85px;
        }

        &__body, &__author {
          width: 518px;
          font-size: 15px;
          line-height: 30px;
        }

        p {text-indent: 21px; margin-bottom: 0px;}

        &__largest {
          display: inline;
        }

        &__image {
          position: absolute;
          margin: 131px 0 0 558px;
          width: 449px;
        }

        &__small, &__large, &__medium, &__medium-landscape {
          display: none;
        }

        &__headline {
          margin: 758px 0 0 558px;
          font-size: 28px;
          line-height: 48px;
          width: 419px;
        }

        &__place-order {
          font-size: 31px;
          margin: 494px 0 0 280px;
        }

        &__underline {
          width: 311px;
          margin: 1050px 0 0 558px;
        }

        &__closing {
          width: 918px;
          line-height: 61px;
          letter-spacing: 0px;
          padding: 32px 0 0 0;
          font-size: 32px;
        }
      }



    } // end $mq-largest
