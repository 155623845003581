body{
  background-color: $beige;
  margin: 0px;
  border-left: 4px solid $red;
  border-right: 4px solid $red;
  height: 100%;

  -webkit-font-smoothing: antialiased;
     font-smoothing: antialiased;
}
body:before, body:after {
  content: "";
  position: fixed;
  background: $red;
  left: 0;
  right: 0;
  height: 4px;
}
body:before {
  top: 0;
  z-index: 999;
}
body:after {
  bottom: 0;
}

.container {
  margin: 0px;
  padding: 0px;
}

h1{
  font-family: $inknut;
  margin-top: 0px;
}

#introduction, #philosophy, #sermons-prayers, #place-order{
  color: $red;
}


///
/// Extra Small
///

    @media #{$mq-extra-small} {

      body {padding: 0 9px 0 9px !important;}

      #introduction, #philosophy, #sermons-prayers, #place-order{border: 2px solid $red;width: 42px;}

    }  // end $mq-extra-small

///
/// Small
///

    @media #{$mq-small} {

      body {padding: 0 20px 0 20px !important;}

      #introduction, #philosophy, #sermons-prayers, #place-order{border: 2px solid $red;width: 42px;}

    } // end $mq-small

///
/// Small Plus
///

    @media #{$mq-small-plus} {

      body {padding: 0 50px 0 50px !important;}

      #introduction, #philosophy, #sermons-prayers, #place-order{border: 2px solid $red;width: 50px;}

    } // end $mq-small-plus

///
/// Medium
///

    @media #{$mq-medium} {

      body {padding: 0 44px 0 44px !important;}

      #introduction, #philosophy, #sermons-prayers, #place-order{border: 2px solid $red;width: 63px;}

    } // end $mq-medium

///
/// Large
///

    @media #{$mq-large} {
      body {padding: 0 60px 0 60px !important;}

      #introduction, #philosophy, #sermons-prayers, #place-order{border: 2px solid $red;width: 63px;}

    } // end $mq-large

///
/// Medium Landscape
///

    @media #{$mq-medium-landscape} {
      body {padding: 0 52px 0 52px !important;}

      #introduction, #philosophy, #sermons-prayers, #place-order{border: 2px solid $red;width: 81px;}

    } // end $mq-medium-landscape

///
/// Largest
///

    @media #{$mq-largest} {
      body {padding: 0 20px 0 20px !important;}

      #introduction, #philosophy, #sermons-prayers, #place-order{border: 2px solid $red;width: 70px;}

    } // end $mq-largest
