///
/// Default
///

    @media #{$mq-default} {

        .head {

            &__all{color: $red;}

            &__header-blurb {
              font-family: $freight;
              color: $blackgrey;
              font-style: italic;
              font-weight: 600;
              padding: 8px 0 8px 0;
            }

            &__modal {background-color: $red;overflow-y: auto;}

            &__fixed {float: right;position: relative;}

            &__mobile-hamburger {
              background-color: $red;
              position: fixed;
              z-index: 100;
              cursor: pointer;
            }

            &__line, &__line:before, &__line:after {
              position: relative;
              display: block;
              content: "";
              background: $beige;
            }

            &__mobile-hamburger:hover {cursor: pointer;}

            &__modal-title, &__modal-author, &__line-break, &__modal-nav {color: $beige;}

            &__modal-author {font-family: $freight;font-style: italic; font-weight: 600;}

            &__mobile-nav {
              list-style: none;
              padding: 0px;
              font-weight: 600;
            }

            &__modal-nav {font-family: $aktiv;}

            &__modal-nav:hover {color: $beige;text-decoration: none;}
        }

    } // end $mq-default


///
/// Extra Small
///

    @media #{$mq-extra-small} {

      .head {

        &__sticky-nav {display: none;}

        &__fixed {right: 50px;}

        &__modal {padding: 9px;}

        &__mobile-hamburger {height: 60px;width: 60px;}

        &__line {margin: 28px 0 0 11px;}

        &__line, &__line:before, &__line:after {width: 40px;height: 3px;}

        &__line:before {bottom: 10px;}

        &__line:after {top: 7px;}

        &__modal-close, &__modal-close:before {
          display: block;
          width: 32px;
          height: 3px;
          position: relative;
          content: "";
          background: $beige;
          opacity: 1;
        }

        &__modal-close {
          transform: rotate(43deg);
          margin-top: 11px;
        }

        &__modal-close:before {transform: rotate(92deg);}

        &__title, &__all {
          font-size: 45px;
          line-height: 60px;
          padding-top: 20px;
          margin-left: -2px;
        }

        &__header-blurb {font-size: 21px; padding: 1px 0 8px 0;}

        &__modal-title {
          font-size: 44px;
          line-height: 67px;
          margin-top: 10px;
        }

        &__modal-author {font-size: 21px;margin: 13px 0 22px 6px;}

        &__line-break {
          display: block;
          width: 12%;
          height: 3px;
          margin-left: 3px;
          content: "";
          background: $beige;
          margin-bottom: 6px;
        }

        &__modal-nav {
          line-height: 46px;
          font-size: 21px;
          letter-spacing: .5px;
        }
      }


    } //end $mq-extra-small


///
/// Small
///

    @media #{$mq-small} {

      .head {

        &__sticky-nav {display: none;}

        &__fixed {right: 39px;}

        &__mobile-hamburger {height: 60px;width: 60px;}

        &__line {margin: 29px 0 0 12px;}

        &__line, &__line:before, &__line:after {width: 40px;height: 3px;}

        &__line:before {bottom: 11px;}

        &__line:after {top: 8px;}

        &__modal-close, &__modal-close:before {
          display: block;
          width: 32px;
          height: 3px;
          position: relative;
          content: "";
          background: $beige;
          opacity: 1;
        }

        &__modal-close {transform: rotate(43deg);margin-top: 11px;}

        &__modal-close:before {transform: rotate(92deg);}

        &__title, &__all {
          font-size: 52px;
          line-height: 67px;
          padding-top: 40px;
          margin-left: -3px;
        }

        &__header-blurb {font-size: 24px; padding: 1px 0 8px 0px;}

        &__modal {padding: 20px;}

        &__modal-title {
          font-size: 53px;
          line-height: 67px;
          margin-top: 20px;
        }

        &__modal-author {font-size: 25px;margin: 13px 0 22px 6px;}

        &__line-break {
          display: block;
          width: 12%;
          height: 3px;
          margin-left: 3px;
          content: "";
          background: $beige;
          margin-bottom: 6px;
        }

        &__modal-nav {
          line-height: 46px;
          font-size: 21px;
          letter-spacing: .5px;
        }
      }


    } // end $mq-small

///
/// Small Plus
///

    @media #{$mq-small-plus} {

      .head {

        &__sticky-nav {display: none;}

        &__fixed {right: 10px;}

        &__mobile-hamburger {height: 60px;width: 60px;}

        &__line {margin: 27px 0 0 12px;}

        &__line, &__line:before, &__line:after {width: 40px;height: 4px;}

        &__line:before {bottom: 11px;}

        &__line:after {top: 7px;}

        &__title, &__all, &__modal-title {font-size: 70px; line-height: 83px;}

        &__modal {padding: 20px 20px 0 50px;}

        &__modal-close, &__modal-close:before {width: 45px;}

      }
    } // end $mq-small-plus


///
/// Medium
///

    @media #{$mq-medium} {

        .head {

          &__sticky-nav {display: none;}

          &__fixed {right: 35px;}

          &__mobile-hamburger {height: 80px;width: 80px;}

          &__line, &__line:before, &__line:after {width: 50px;height: 4px;}

          &__line:before {bottom: 14px;}

          &__line:after {top: 10px;}

          &__line {margin: 38px 0 0 17px;}

          &__modal {padding: 0 32px 0 0;}

          &__modal-close, &__modal-close:before {
            display: block;
            width: 50px;
            height: 4px;
            position: relative;
            content: "";
            background: $beige;
            opacity: 1;
          }

          &__modal-close {
            transform: rotate(43deg);
            margin: 42px 0 0 0;
          }

          &__modal-close:before {transform: rotate(92deg);}

          &__modal-title {
            font-size: 100px;
            line-height: 124px;
            padding: 40px 0 0 44px;
            margin: 0 0 10px -5px;
          }

          &__modal-author {font-size: 34px;padding-left:44px;}

          &__line-break {
            border: 2px solid $beige;
            width: 58px;
            margin: 21px 0 12px 53px;
          }

          &__modal-nav {
            font-size: 26px;
            line-height: 51px;
            padding-left: 53px;
          }

          &__title, &__all {
            font-size: 100px;
            line-height: 124px;
            padding-top: 40px;
            margin: 0 0 10px -5px;
          }

          &__header-blurb {font-size: 34px;}

        }

    } // end $mq-medium


///
/// Large
///

    @media #{$mq-large} {

      .head {

        &__mobile-hamburger {display: none;}

        &__sticky-nav {
          list-style: none;
          display: inline;
          overflow: hidden;
          position: absolute;
          margin-top: 408px;
          padding: 0px;
          height: 70px;
        }

        .fixed {
        	position: fixed;
        	top: 0;
          margin: 0px;
        	height: 70px;
        	z-index: 1;
          background-color: $beige;
          width: 90%;
          li{margin-top: 20px;}
        }

        &__sticky-nav li {float: left;padding-right: 35px;}

        &__sticky-nav &__navigation-options {
          font-family: $aktiv;
          color: $blackgrey;
          font-weight: 600;
          font-size: 17px;
          letter-spacing: .5px;
          display: block;
        }

        &__title, &__all {
          font-size: 100px;
          padding-top: 50px;
          font-weight: 400;
          line-height: 130px;
        }

        &__header-blurb {margin-top: 18px; font-size: 32px;}

        &__place-order {
          font-family: $aktiv;
          color: $red;
          font-weight: 600;
          font-size: 17px;
          letter-spacing: .5px;
        }

        &__sticky-nav &__place-order:hover {color: $red;}

        &__sticky-nav &__navigation-options:hover, &__place-order:hover {
          color: $blackgrey;
          text-decoration: none;
        }

      }

    } // end $mq-large

    ///
    /// ipad landscape
    ///

      @media #{$mq-medium-landscape} {

        .head {

          .fixed {
            position: fixed;
            top: 0;
            margin: 0px;
            height: 70px;
            z-index: 1;
            background-color: $beige;
            width: 91%;
            li{
              margin-top: 20px;
            }
          }

          &__title, &__all {
            font-size: 120px;
            margin-top: 0;
            padding-top: 41px;
            line-height: 162px;
            letter-spacing: -2px;
            font-weight: 100;
          }

          &__header-blurb {margin: 9px 0 0 3px;font-size: 34px;}

          &__sticky-nav {margin: 465px 0 0 5px;}

          &__sticky-nav li {padding-right: 46px;}

          &__sticky-nav &__navigation-options, &__place-order {font-size: 20px;}

        }

      } //end $mq-medium-landscape


///
/// Largest
///

    @media #{$mq-largest} {

      .head {

        .fixed {
        	position: fixed;
        	top: 0;
          margin: 0px;
        	height: 70px;
        	z-index: 1;
          background-color: $beige;
          width: 1034px;
          li{
            margin-top: 20px;
          }
        }

        &__title, &__all {
          font-size: 144px;
          padding-top: 94px;
          letter-spacing: 3px;
          line-height: 193px;
          font-weight: 100;
        }

        &__header-blurb {font-size: 40px;margin-top: 12px;}

        &__sticky-nav {margin-top: 600px; font-size: 21px;}

        &__sticky-nav li {padding-right: 61px;}
      }

    } // end $mq-largest
