///
/// Default
///

@media #{$mq-default} {

  .footer {
    background: $red;
    width: calc(100% + 8px);
    padding: 25px 10px;
    position: absolute;
    bottom: -150px;
    left: -4px;
  }

  .footer__copy {
    display: block;
    max-width: 1025px;
    color: white;
    font-family: $freight;
    font-style: italic;
    font-size: 20px;
    margin: 0 auto;
    padding: 0;
  }

} // end $mq-default


///
/// Extra Small
///

@media #{$mq-extra-small} {

} //end $mq-extra-small


///
/// Small 375px
///

@media #{$mq-small} {

  .footer {
    bottom: -120px;
  }

} // end $mq-small

///
/// Small Plus 540px
///

@media #{$mq-small-plus} {

  .footer {
    bottom: -80px;
  }

} // end $mq-small-plus


///
/// Medium 768px
///

@media #{$mq-medium} {

  .footer {
    padding: 20px;
    bottom: 0;
  }

  .footer__copy {
    font-size: 22px;
  }

} // end $mq-medium


///
/// Large 960px
///

@media #{$mq-large} {

  .footer {

  }

  .footer__copy {
    font-size: 24px;
  }

} // end $mq-large

///
/// ipad landscape 1024px
///

@media #{$mq-medium-landscape} {

  .footer {
    bottom: -40px;
  }

  .footer__copy {
    font-size: 24px;
  }

} //end $mq-medium-landscape


///
/// Largest 1160px
///

@media #{$mq-largest} {

  .footer {
    bottom: 0px;
  }

  .footer__copy {
    font-size: 26px;
  }

} // end $mq-largest
