html {
  height: auto !important;
  min-height: auto !important;
}

#EJEJC_overlay {
  width: 100% !important;
  background: $red !important;
  opacity: 1 !important;
}

#EJEJC_window {
  left: 50% !important;
  // top: 50% !important;
  width: 90% !important;
  max-width: 900px;
  padding: 2%;
  transform: translateX(-50%);

  background: $beige !important;
  border: 2px solid $red !important;
  box-sizing: content-box;

  * {
    font-family: "aktiv-grotesk", sans-serif !important;
    font-size: 16px !important;
  }

  a {
    text-decoration: none !important;
  }

  #EJEJC_title {
    width: 100% !important;
    background: transparent !important;
  }

  #EJEJC_closeWindowButton {
    color: $red !important;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  #EJEJC_iframeContent {
    width: 100% !important;
    height: auto !important;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch;
  }

  form > table:nth-of-type(1) {
    display: none;
  }

  #ejejctable {
    width: 100% !important;
  }

  form > table:nth-of-type(2) {
    margin-top: 30px !important;
  }

  form > table:nth-of-type(2) > tbody > tr:first-child td {
    height: auto !important;
    padding: 5px 10px !important;
    background: $red !important;
    color: $beige !important;
    font-size: 10px !important;

    b {
      font-size: 10px !important;
      text-transform: uppercase;
    }
  }

  form > table:nth-of-type(2) > tbody td {
    padding: 5px 10px !important;
    font-size: 12px !important;

    b {
      font-size: 12px !important;
      text-transform: uppercase;
    }
  }

  #tdPmnt {
    display: none;
  }

  #country1,
  #state1,
  #state2 {
    font-size: 12px !important;
  }

  input[type=text] {
    padding: 5px 10px !important;
    border-color: #888 !important;
    border-style: solid;
    border-width: 1px !important;
    font-size: 12px !important;
  }

  select {
    height: 30px;
    // padding: 5px 10px !important;
    border-color: #888 !important;
    font-size: 12px !important;
  }

  #btnUpdtCart,
  #btnContShop,
  .checkoutMethod {
    height: auto !important;
    padding: 10px 10px !important;

    background-color: transparent !important;
    border-color: $red !important;
    border-radius: 0;
    border-style: solid;
    border-width: 2px !important;
    color: $red !important;
    font-size: 12px !important;
    font-weight: bold !important;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background-color: $red !important;
      color: $beige !important;
    }
  }

  #tdPmntOptions {
    border-top: 1px dashed #aaa !important;
    padding: 20px 0 0 !important;
  }

  .checkoutMethod {
    background-color: $red !important;
    color: $beige !important;

    &:hover {
      background-color: transparent !important;
      color: $red !important;
    }
  }
}


