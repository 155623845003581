@media #{$mq-default} {

    .sermons-prayers {

      audio {
        display: none;
      }

      &__title {
        color: $blackgrey;
      }

      &__intro {
        color: $blackgrey;
        font-family: $aktiv;
        font-weight: 500;
        color: $blackgrey;
        text-align: justify;
      }

      &__entry-title {
        font-family: $aktiv;
        font-weight: 500;
        color: $blackgrey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__image {display: block; position: relative;}

      &__video {width: 90%; display: none;}

      &__download {
        font-family: $aktiv;
        font-weight: 600;
        color: $red;
      }

      &__audio-buttons {position: relative;}

      &__play-button{left: 15px;}

      &__pause-button{display: none; left: 12px;}

      &__play-button, &__pause-button {
        position: absolute;
        cursor: pointer;
      }

      &__download a{color:$red; text-decoration: none;}

      &__refresh {
        color: $red;
        font-family: $inknut;
        font-weight: 500;
      }

      &__refresh a{color:$red; text-decoration: none;}

      &__underline {color: $red; border: 2px solid $red;}

    }

} // end $mq-default


///
/// Extra Small
///

    @media #{$mq-extra-small} {

      .sermons-prayers {

        &__title {
          font-size: 40px;
          line-height: 58px;
          margin-top: 20px;
          margin-left: -2px;
        }

        p {text-indent: 24px;}

        &__intro{
          font-size: 14px;
          line-height: 24px;
          letter-spacing: .1px;
          margin-bottom: 30px;
        }

        &__tile {
          overflow: hidden;
          -moz-column-count: 1;
          -webkit-column-count: 1;
          column-count: 1;
        }

        &__block {padding-bottom: 30px;}

        &__image {height: auto; width: 100%;}

        &__entry-title {font-size: 15px; margin: 5px 0 5px 0; width: 240px;}

        &__audio-buttons {bottom: 42px;}

        &__download {font-size: 13px;}

        &__refresh {font-size: 24px;}

        &__underline {margin:17px 0 20px 0; width: 220px;}

      }
    } // end $$mq-small


///
/// Small
///

    @media #{$mq-small} {

      #block {overflow: hidden;}

      .sermons-prayers {

        &__title {
          font-size: 44px;
          line-height: 60px;
          margin-top: 20px;
          margin-left: -2px;
        }

        &__intro{
          font-size: 14px;
          line-height: 24px;
          letter-spacing: .1px;
           margin-bottom: 20px;
        }

        p {text-indent: 21px;}

        &__tile {
          -moz-column-count: 1;
          -webkit-column-count: 1;
          column-count: 1;
        }

        &__block {padding-bottom: 30px;}

        &__image {height: auto; width: 100%;}

        &__entry-title {font-size: 18px; margin: 5px 0 5px 0; width: 270px;}

        &__audio-buttons {bottom: 42px;}

        &__download {font-size: 14px;}

        &__refresh {font-size: 24px;}

        &__underline {margin:17px 0 20px 0; width: 220px;}

      }
    } //end $mq-small




///
/// Small Plus
///

    @media #{$mq-small-plus} {

      .sermons-prayers {

        &__title {font-size: 42px;}

        p {text-indent: 21px;}

        &__tile {
          -moz-column-count: 1;
          -webkit-column-count: 1;
          column-count: 1;
        }

        &__block {padding-bottom: 30px;}

        &__image {height: auto; width: 100%;}

        &__entry-title {font-size: 18px; margin: 5px 0 5px 0; width: 270px;}

        &__audio-buttons {bottom: 60px;}

        &__download {font-size: 14px;}

        &__refresh {font-size: 24px;}

        &__underline {margin:17px 0 20px 0; width: 220px;}

      }
    } //end $mq-small-plus



///
/// Medium
///

    @media #{$mq-medium} {

        .sermons-prayers {

          &__title {
            font-size: 54px;
            letter-spacing: -3px;
            margin-bottom: 16px;
          }

          &__intro{
            font-size: 12px;
            line-height: 21px;
            letter-spacing: .1px;
            margin-bottom: 30px;
          }

          &__body{font-size: 12px;line-height: 24px;}

          p {text-indent: 21px;}

          &__tile {
            margin-bottom: 20px;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
          }

          &__block {
            -webkit-column-break-inside: avoid;
              page-break-inside: avoid;
                break-inside: avoid;
            padding-bottom: 10px;
            break-inside: avoid;
            padding-bottom: 25px;
          }

          &__image {height: auto; width: 90%;}

          &__entry-title {
            width: 240px;
            font-size: 17px;
            margin: 5px 0 5px 0;
          }

          &__download {font-size: 15px;}

          &__audio-buttons {bottom: 40px;}

          &__refresh {font-size: 28px;}

          &__underline {margin:17px 0 20px 0; width: 257px;}

        }
    } // end $mq-medium


///
/// Large
///

    @media #{$mq-large} {

        .sermons-prayers {

          &__title {
            font-size: 68px;
            margin-top: 15px;
            font-weight: 400;
          }

          &__intro {
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 30px;
          }

          p {text-indent: 21px;}

          &__tile {
            margin-bottom: 0px;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
          }

          &__block {padding-bottom: 30px; break-inside: avoid-column;}

          &__image {height: auto; width: 90%; padding-bottom: 5px;}

          &__entry-title {font-size: 12px; width: 200px; display: inline;}

          &__download {
            font-size: 12px;
            position: relative;
            float: right;
            right: 10%;
            margin-top: 0;
          }

          &__audio-buttons {bottom: 45px;}

          &__refresh {font-size: 25px; letter-spacing: .5px; margin-top: 0;}

          &__underline {margin:17px 0 20px 0; width: 240px;}

        }
    } // end $mq-large


///
/// ipad landscape
///

    @media #{$mq-medium-landscape} {

        .sermons-prayers {

          &__title {
            font-size: 72px;
            margin: 39px 0 22px 0;
          }

          &__intro {
            font-size: 14px;
            line-height: 26px;
          }

          p {text-indent: 21px;}

          &__tile {
            margin-bottom: 0px;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
          }

          &__block {break-inside: avoid-column;}

          &__image {height: auto; width: 90%; padding-bottom: 5px;}

          &__entry-title {
            font-size: 14px;
            width: 240px;
            display: inline;
          }

          &__download {
            font-size: 13px;
            position: relative;
            float: right;
            right: 10%;
            margin-top: 0;
          }

          &__audio-buttons {bottom: 45px;}

        }
    } // end $mq-medium-landscape



///
/// Largest
///

    @media #{$mq-largest} {

        .sermons-prayers {

          &__title {
            margin:38px 0 41px 0;
            font-size: 85px;
          }

          &__intro {
            font-size: 15px;
            line-height: 30px;
          }

          p {text-indent: 21px;}

          &__tile {
            margin-bottom: 0px;
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
          }

          &__block {padding-bottom: 30px; break-inside: avoid-column;}

          &__image {height: auto; width: 90%;}

          &__entry-title {font-size: 14px; margin: 5px 0 5px 0;}

          &__download {font-size: 13px;}

          &__audio-buttons {bottom: 45px;}

          &__refresh {font-size: 25px; letter-spacing: .5px; margin-top: 0;}

          &__underline {margin:17px 0 20px 0; width: 240px;}

        }
    } // end $mq-largest
