///
/// Default
///

    @media #{$mq-default} {

        .philosophy {

            &__mask {
              width: 95%;
              height: 500px;
              overflow: hidden;
            }

            &__content {
              column-fill: auto;
              text-align: justify;
              height: 100%;
              width: 10000px;
            }

            &__title {
              color: $blackgrey;
            }

            &__author {
              font-family: $freight;
              font-style: italic;
              font-weight: 600;
              color: $blackgrey;
            }

            &__body {
              font-family: $aktiv;
              font-weight: 500;
              color: $blackgrey;
            }

            &__top {
              color: $red;
              font-family: $inknut;
              font-weight: 500;
            }

            &__top-underline {border: 2px solid $red;}

            &__prev, &__next {
              color: $red;
              font-family: $inknut;
              font-weight: 600;
              cursor: pointer;
              display: inline-block;
            }

            &__portrait {
              width: 100%;
            }

        }

    } // end $mq-default


///
/// Extra Small
///

    @media #{$mq-extra-small} {

      .philosophy {

        &__start {margin-top: 10px;}

        &__mask {
          height: 325px;
        }

        &__title {
          font-size: 40px;
          line-height: 58px;
          margin-top: 20px;
          margin-left: -2px;
        }

        &__author {
          font-size: 21px;
          margin-left: 0px;
        }

        &__content {
          column-count: 34;
          column-gap: 17px;
        }

        &__top, &__top-underline {display: none;}

        &__body{
          font-size: 14px;
          line-height: 24px;
          letter-spacing: .1px;
        }

        p {text-indent: 24px;}

        &__prev, &__next {font-size: 24px;}

        &__prev {margin-right: 40px;}

        .prev-button, .next-button {margin-bottom: 10px;}

        &__underline {border: 2px solid $red;}

        &__prev-underline {width: 65px;}

        &__next-underline {width: 64px;}

        &__portrait {padding: 25px 0 30px 0;}

      }

    } // end $mq-extra-small


///
/// Small
///

    @media #{$mq-small} {

      .philosophy {

        &__start {margin-top: 10px;}

        &__mask {
          height: 380px;
        }

        &__title {
          font-size: 44px;
          line-height: 60px;
          margin-top: 20px;
          margin-left: -2px;
        }

        &__author {
          font-size: 24px;
          margin-left: 0px;
        }

        &__content {
          column-count: 32;
          column-gap: 15px;
        }

        &__top, &__top-underline {display: none;}

        &__read {font-size: 24px; padding: 6px 0 0 0;}

        &__body{
          font-size: 14px;
          line-height: 24px;
          letter-spacing: .1px;
        }

        &__last-para {margin-bottom: 0;}

        p {text-indent: 24px;}

        &__prev, &__next {font-size: 24px;}

        &__prev {margin-right: 40px;}

        .prev-button, .next-button {margin-bottom: 7px;}

        &__underline {border: 2px solid $red;}

        &__prev-underline {width: 65px;}

        &__next-underline {width: 64px;}

        &__portrait {padding: 25px 0 30px 0;}

      }

    } // end $$mq-small


///
/// Small Plus
///

    @media #{$mq-small-plus} {

      .philosophy{

        &__title {font-size: 42px;}

        &__author {margin-top: 15px;}

        &__mask {
          margin-top: 30px;
        }

        &__content {
          column-count: 30;
          column-gap: 30px;
        }
      }
    } //end $mq-small-plus


///
/// Medium
///

    @media #{$mq-medium} {

        .philosophy {

            &__title {font-size: 54px; margin-top: 25px;}

            &__author {
              font-size: 28px;
              padding-top: 5px;
            }

            &__content {
              column-count: 31;
            }

            &__body {
              font-size: 12px;
              line-height: 21px;
            }

            p {text-indent: 24px;}

            &__article-top {padding-top: 20px;}

            &__top-underline {
              width: 223px;
              margin: 44px 0 0 0;
              display: inline-block;
              position: relative;
              float: left;
            }

            &__top{
              font-size: 28px;
              display: inline;
              position: relative;
              right: 113px;
            }

            &__top:hover {color: $red; text-decoration: none;}

            &__read-more {display: none;}

            &__portrait {
              width: 89%;
              padding: 35px 86px 30px 2px;
            }

            &__prev, &__next {
              padding-top: 5px;
              font-size: 30px;
            }

            &__next {
              padding-left: 33px;
            }

            &__underline {
              border: 2px solid $red;
            }

            &__prev-underline {
              margin-top:6px;
              width: 83px;
            }

            &__next-underline {
              margin-top:6px;
              width: 82px;
            }
        }

    } // end $mq-medium


///
/// Large
///

    @media #{$mq-large} {

      .philosophy {

        &__start {
          margin: 25px 0 34px 0;
        }

        &__title {font-size: 68px; margin-bottom: 24px; font-weight: 400;}

        &__author {
          font-size: 32px;
        }

        &__content {
          column-count: 25;
        }

        &__body {
          font-size: 12px;
          line-height: 24px;
          text-align: justify;
        }

        p {text-indent: 24px;}

        &__read-more {display: none;}

        &__prev, &__next {
          font-size: 25px;
          padding-top: 0;
          margin-top: 10px;
        }

        &__underline {
          border: 2px solid $red;
          margin-top: 5px;
        }

        &__prev-underline {
          width: 63px;
        }

        &__next-underline {
          width: 69px;
        }

        &__portrait {
          padding: 35px 86px 35px 2px;
        }
      }


    } // end $mq-large


///
/// ipad landscape
///

    @media #{$mq-medium-landscape} {

      .philosophy {

        &__start {margin: 26px 0 44px 0;}

        &__title {font-size: 72px;}

        p {text-indent: 24px;}

        &__author {font-size: 34px;}

        &__mask {
          margin-top: 40px;
        }

        &__content {
          column-count: 34;
        }

        &__body {
          font-size: 14px;
          line-height: 26px;
          text-align: justify;
        }

        &__read-more {display: none;}

        &__prev, &__next {
          padding-top: 7px;
          font-size: 35px;
        }

        &__next-button, &__next {
          margin-left: 28px;
          padding: 0;
        }

        .next-button {
          margin: 0;
        }

        &__next-underline {
          width: 90px;
          margin-left: 3px;
        }

        &__prev-underline {
          width: 92px;
        }

        &__underline {
          margin-top: 9px;
          border: 2px solid $red;
        }

        &__portrait {
          margin: 2px 0 20px;
          width: 91%;
        }
      }
    }

///
/// Largest
///

    @media #{$mq-largest} {

      .philosophy {

        &__start {margin: 36px 0 36px 0;}

        &__title {font-size: 70px;}

        &__author{font-size: 28px; padding-top: 10px;}

        p {text-indent: 24px;}

        &__content {
          column-count: 30;
        }

        &__body {
          font-size: 15px;
          line-height: 30px;
        }

        &__read-more {display: none;}

        &__prev, &__next {font-size: 26px;}

        &__underline {
          border: 2px solid $red;
          margin-top: 10px;
        }

        &__prev-underline { width: 71px;}

        &__next-underline { width: 71px; margin-left: 0px;}

        &__portrait {
          width: 75%;
          margin-top: 25px;
          position: relative;
          //right: 224px;
        }

      }

    } // end $mq-largest
